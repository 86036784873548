import * as React from 'react'

import { Stack, Typography } from '@mui/material'

import { SeoHeaders } from 'shared/components'

const browser = (typeof window !== 'undefined') && window

const NotFoundPage = () => (browser && (
  <React.Fragment>
    <SeoHeaders
      title='No encontrado'
      description='No encontrado'
    />
    <Stack
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
    >
      <Typography
        variant='h1'
      >
        404
      </Typography>
      <Typography
        variant='h4'
        component='h2'
      >
        No encontrado
      </Typography>
    </Stack>
  </React.Fragment>
))

export default NotFoundPage
